import { Thumbnail } from './Thumbnail';
import { Variant } from './Product/Variant';

export class DiscoverWheel{
    title: string;
    items: DiscoverItem[];
}

export interface CTAType {
    type: string;
    text: string;
    id: number;
}

export class DiscoverItem {
    type: string;
    displayType: string;
    presentationMode?: string;
    content?: WheelContent;
    cta?: CTAType;
    isOnSpotlight?: boolean;

    static GetItemUrl(item: DiscoverItem): string {
        if(item.content.readableUrl && item.content.readableUrl.trim().length > 0) {
            return '/' + item.type + '/' + item.content.readableUrl;
        }

        return '/' + item.type + '/' + item.content.id;
    }
}

export class WheelContent{
    id: number;
    title: string;
    description: string;
    thumbnails?: Thumbnail[];
    ageRating: string;
    url?: string;
    tags?: string[];
    printType?: string;
    readableUrl: string;
    saleStatus: string;
    volumeId?: number;
    volumeNumber?: number;
    seriesName?: string;
    seriesId?: number;
    volumeName?: string;
    chapterName?: string;
    chapterNumber?: number;
    scheduleStatus?: string;
    publishDate?: string;
    variants?: Variant[];
    isOnSale?: boolean;
    author: string;
    price?: number;
    seriesReadableUrl?: string;
    volumeReadableUrl?: string;
    relativeName?: string;
}