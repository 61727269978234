import { DashCreator, Publishable, RestrictedTerritory } from './Dashboard';
import {Thumbnail} from "./Thumbnail";
import CannotPublishItem from './CannotPublishItem';
import { AssetLinkGroup } from './AssetLinkGroup';
import { ProductCreator } from './DashboardProduct';
import { Creator } from './Creator';
import update from 'immutability-helper';

class DashExternalLink {
  id: number;
  name: string;
  externalId: number;
}

class DashSeriesMetaData {
  maxAgeRating: number;
}

//ToDO: Inherit Publishable.
class DashboardSeries extends Publishable {
  title: string = '';
  description: string = '';
  metaDescription?: string;
  thumbnails: Thumbnail[] = [];
  //defaultThumbnail: Thumbnail = null;
  genres: number[] = [];
  readableUrl: string;
  color: string;
  externalLinks: DashExternalLink[];
  assetLinkGroups: AssetLinkGroup[];
  metaData: DashSeriesMetaData;
  completionStatus: string;

  originalLanguageTitle: string = '';
  romanizedTitle: string = '';
  altTitles: string = '';
  isUsingOverrideCreators: boolean = false;
  overrideCreators: ProductCreator[] =[];
  defaultCreators:Creator[];
  restrictedTerritories: RestrictedTerritory[] = [];
  unlisted = false;

  canPublish(): boolean {
    return (this.title && this.title.length >0
      && this.description && this.description.length > 0
      && this.genres.length > 0);
  }

  mutate(newProps: Partial<DashboardSeries>): DashboardSeries {
    const x = this as DashboardSeries;
    return Object.assign(new DashboardSeries(), x, newProps);
  }

  getCannotPublishReasons(): CannotPublishItem[] {
    const missingItems: CannotPublishItem[] = [];
    const emptyFields = this.getSeriesEmptyFields();
    if(emptyFields && emptyFields.length > 0) {
      missingItems.push({
        title: "Missing Series Information",
        description: "All the following fields must be filled in before you can publish this product",
        subItems: emptyFields
      });
    }

    if(this.genres.length === 0) {
      missingItems.push({
        title: "No genres selected",
        description: "A published series must belong to at least one genre",
      });
    }

    return missingItems;
  }

  getSeriesEmptyFields(): CannotPublishItem[] {
    const missingItems = [];
    if((this.title?.length ?? 0) === 0) {
      missingItems.push({ title: "Product Title"});
    }

    if(!this.description || this.description.length === 0) {
      missingItems.push({ title: "Description"});
    }

    return missingItems;
  }

  getOriginal(): DashboardSeries {
    return this.original? this.original as DashboardSeries : null;
  }

  copyFromOriginal(): void {
    super.copyFromOriginal();

    const item = this.getOriginal();
    this.title = item.title;
    this.thumbnails = item.thumbnails;
    this.description = item.description;
    this.metaDescription = item.metaDescription;
    this.genres = item.genres;
    //this.defaultThumbnail = item.defaultThumbnail;
    this.readableUrl = item.readableUrl;
    this.color = item.thumbnails[0]?.color;
    this.externalLinks = item.externalLinks;
    this.metaData = item.metaData;
    this.completionStatus = item.completionStatus;
    this.originalLanguageTitle = item.originalLanguageTitle;
    this.romanizedTitle = item.romanizedTitle;
    this.altTitles = item.altTitles;
    this.isUsingOverrideCreators = item.isUsingOverrideCreators;
    this.overrideCreators = item.overrideCreators?.map(x => update(x, {creator: {$set: DashCreator.createFrom(x.creator) }})).sort((a, b) => {
          if (a.ordinal !== b.ordinal) {
            return a.ordinal - b.ordinal;
          }
          return a.creator.getName().localeCompare(b.creator.getName());
        },
    ),
    this.defaultCreators = item.defaultCreators;
    this.assetLinkGroups = item.assetLinkGroups?.map(AssetLinkGroup.createFromApi) ?? [];
    this.restrictedTerritories = item.restrictedTerritories;
    this.unlisted = item.unlisted;
  }

  initialize(): void {
    const orig = new DashboardSeries();
    orig.status=Publishable.STATUS_NEW;
    this.original = orig;
    this.copyFromOriginal();
    this.status = Publishable.STATUS_DRAFT;
  }

  hasChanged(): boolean {
    // Function to log differences between two objects
    const logObjectDiff = (fieldName: string, fieldThis: any, fieldOrig: any) => {
      if (fieldThis !== fieldOrig) {
        console.log(`Difference in ${fieldName}:`);
        console.log(`   this.${fieldName}:`, fieldThis);
        console.log(`   orig.${fieldName}:`, fieldOrig);
      }
    };

    if (!super.hasChanged()) {
      const orig = this.getOriginal();

      console.log("orig:", orig);
      logObjectDiff('title', this.title, orig.title);
      logObjectDiff('originalLanguageTitle', this.originalLanguageTitle, orig.originalLanguageTitle);
      logObjectDiff('romanizedTitle', this.romanizedTitle, orig.romanizedTitle);
      logObjectDiff('altTitles', this.altTitles, orig.altTitles);
      logObjectDiff('description', this.description, orig.description);
      logObjectDiff('metaDescription', this.metaDescription, orig.metaDescription);
      logObjectDiff('genres', JSON.stringify(this.genres.sort()), JSON.stringify(orig.genres.sort()));
      logObjectDiff('readableUrl', this.readableUrl, orig.readableUrl);
      logObjectDiff('color', this.color, this.thumbnails[0]?.color);
      logObjectDiff('completionStatus', this.completionStatus, orig.completionStatus);
      logObjectDiff('assetLinkGroups', this.assetLinkGroups, orig.assetLinkGroups);
      logObjectDiff("overrideCreators", JSON.stringify(this.overrideCreators), JSON.stringify(orig.overrideCreators));
      logObjectDiff("isUsingOverrideCreators", this.isUsingOverrideCreators, orig.isUsingOverrideCreators);
      logObjectDiff("unlisted", this.unlisted, orig.unlisted);
      return (
          orig.title !== this.title
          || orig.originalLanguageTitle !== this.originalLanguageTitle
          || orig.romanizedTitle !== this.romanizedTitle
          || orig.altTitles !== this.altTitles
          || orig.description !== this.description
          || orig.metaDescription !== this.metaDescription
          || orig.unlisted !== this.unlisted
          || JSON.stringify(orig.genres.sort()) !== JSON.stringify(this.genres.sort())
          || orig.readableUrl !== this.readableUrl
          || this.color !== this.thumbnails[0]?.color
          || orig.completionStatus !== this.completionStatus
          || JSON.stringify(orig.assetLinkGroups) !== JSON.stringify(this.assetLinkGroups)
          || JSON.stringify(orig.overrideCreators) !== JSON.stringify(this.overrideCreators)
            || orig.isUsingOverrideCreators !== this.isUsingOverrideCreators
      );
    }

    return true;
  }
}

export {DashboardSeries, DashExternalLink};
