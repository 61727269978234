import { atom } from 'jotai';
import { HomeModel } from '../Models/HomeModel';
import { Address } from '../Models/Address';
import { OrderShippingMethod } from '../Models/OrderShippingMethod';
import { Country } from '../Models/Country';

export enum LoginType {
    Normal,
    Checkout,
    Signup,
    SignupCheckout,
}

// export const LoginTypeAtom = atom<LoginType>(LoginType.Normal);
export const LoginOverlayVisible = atom(false);
export const AccountOverlayVisible = atom(false);
export const CheckoutOverlayVisible = atom(false);
export const MatureContentVisible = atom(false);


export const LoginTypeStore = ((): { getLoginType: () => LoginType; setLoginType: (type: LoginType) => void } => {
    let loginType: LoginType = LoginType.Normal;
    const getLoginType = () => loginType;

    const setLoginType = (type: LoginType) => {
        if (loginType === LoginType.Checkout && type === LoginType.Signup) {
            loginType = LoginType.SignupCheckout;
        } else {
            loginType = type;
        }
        // console.log('type is', loginType);
    };

    return { getLoginType, setLoginType };
})();


export const CheckoutBillingAddressAtom = atom<Address> (Address.FromPartial({ }))
export const CheckoutShippingAddressAtom = atom<Address> (Address.FromPartial({
    country: 'US',
}))

export const ResetBillingAddressAtom = atom (null, (get, set) => {
    set(CheckoutBillingAddressAtom, Address.FromPartial({}))
})

export const ResetShippingAddressAtom = atom (null, (get, set) => {
    set(CheckoutShippingAddressAtom, Address.FromPartial({country: 'US'}))
})

export const CheckoutOrderShippingMethodAtom = atom<OrderShippingMethod|false> (false)
export const CheckoutSetBillingSameAsShipping = atom (true)

export const CheckoutCountries = atom<Country[]|false>(false);