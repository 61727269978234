import {OrderedPublishable, Publishable} from "./Dashboard";
import { OrderedItem } from './Dashboard/Publishable';
import { NameTuple } from './FilterTuple';
import { AgeRatingTier } from './BrowseEnums';

class DashboardHomeListEntry extends OrderedPublishable {
  title: string;
  filterCriteria: FilterCriteria;
  type: string;
  curatedItems: HomeListItem[] = [];
  customCallToActionText?:string;
  customCallToActionUrl?:string;


  //Mutators for changing visible editing state (not saved to/called from DB)
  editingContents: boolean;
  editingText: boolean;

  getOriginal(): DashboardHomeListEntry {
    return this.original? this.original as DashboardHomeListEntry : null;
  }

  canPublish(): boolean {
    return this.title && this.title.length > 0;
  }

  copyFromOriginal(): void {
    super.copyFromOriginal();

    const item = this.getOriginal();
    this.title = item.title;
    this.type = item.type;
    this.customCallToActionText = item.customCallToActionText;
    this.customCallToActionUrl = item.customCallToActionUrl;
    this.curatedItems = JSON.parse(JSON.stringify(item.curatedItems));
    this.filterCriteria = {...item.filterCriteria};
    this.editingContents=false;
    this.editingText = false;
    // this.filterCriteria.genres = []
  }

  //Anything except order.
  hasChanged(): boolean {
    if(!super.hasChanged()) {
      const orig = this.getOriginal();
      return (orig.title !== this.title
          || orig.type !== this.type
          || orig.customCallToActionText !== this.customCallToActionText
          || orig.customCallToActionUrl !== this.customCallToActionUrl
          || orig.filterCriteria?.type !== this.filterCriteria?.type
          || orig.filterCriteria?.genres !== this.filterCriteria?.genres
          || orig.filterCriteria?.sort !== this.filterCriteria?.sort
          || orig.filterCriteria?.ipTagId !== this.filterCriteria?.ipTagId
          || orig.filterCriteria?.imprintId !== this.filterCriteria?.imprintId
          || orig.filterCriteria?.category !== this.filterCriteria?.category
          || orig.filterCriteria?.subCategory !== this.filterCriteria?.subCategory
          || orig.filterCriteria?.includeSeries !== this.filterCriteria?.includeSeries
          || orig.filterCriteria?.publishStatus !== this.filterCriteria?.publishStatus
          || orig.filterCriteria?.scheduleStatus !== this.filterCriteria?.scheduleStatus
          || orig.filterCriteria?.ageRatings !== this.filterCriteria?.ageRatings
          || orig.filterCriteria?.hasDownloadablePriceTypeFlag !== this.filterCriteria?.hasDownloadablePriceTypeFlag
          || orig.filterCriteria?.hasShippablePriceTypeFlag !== this.filterCriteria?.hasShippablePriceTypeFlag
          || orig.filterCriteria?.isOnSale !== this.filterCriteria?.isOnSale
          || orig.filterCriteria?.SeriesStatus !== this.filterCriteria?.SeriesStatus
          || orig.filterCriteria?.MinPrice !== this.filterCriteria?.MinPrice
          || orig.filterCriteria?.MaxPrice !== this.filterCriteria?.MaxPrice
          || orig.filterCriteria?.newOnly !== this.filterCriteria?.newOnly
          || JSON.stringify(orig.curatedItems) !== JSON.stringify(this.curatedItems));
    }

    return true;
    // if(this.original) {
    //   let orig = this.original;
    // }
  }

  //For new wheel to be added.
  initialize(): void {
    const orig = new DashboardHomeListEntry();
    orig.status=Publishable.STATUS_NEW;
    orig.isForApp=false;
    orig.isForWeb=false;
    this.original = orig;
    this.copyFromOriginal();
    this.status = Publishable.STATUS_DRAFT;
    this.order=0;

  }
}

function createDashboardHomeListEntryFrom(item: DashboardHomeListEntry): DashboardHomeListEntry {
    const dbc = new DashboardHomeListEntry();
    dbc.original = item;
    dbc.copyFromOriginal();

    return dbc;
}

const SortOrderEnumObj= {
  NewAndPopular : 0,
  Recentlyadded:1,
  RecommendedForYou:2,
  DateAddedDescending:3,
  DateAddedAscending:4,
  PublishDateDescending:5,// Actually the ReleaseDate for Publications and the first Product Release date for Series
  PublishDateAscending:6,// Actually the ReleaseDate for Publications and the first Product Release date for Series
  AllTimePopular:7,
  TitleDescending:8,
  TitleAscending:9,
  Trending:10
} as const


export type SortOrderEnum = (typeof SortOrderEnumObj)[keyof typeof SortOrderEnumObj];

export function intToSortOrderEnum(value: number): SortOrderEnum | undefined {
  for (const key in SortOrderEnumObj) {
    if (SortOrderEnumObj[key] === value) {
      return key as unknown as SortOrderEnum;
    }
  }
  return undefined; // Return undefined for invalid values
}

const PublishStatusObj= {
  Draft: 0,
  Scheduled: 1,
  ScheduledPreOrder: 2,
  ScheduledComingSoon: 3,
  Published: 4,
  Removed: 5,
  Ended: 6
} as const
export type PublishStatusEnum = (typeof PublishStatusObj)[keyof typeof PublishStatusObj];
export function intToPublishStatusEnum(value: number): PublishStatusEnum | undefined {
  for (const key in PublishStatusObj) {
    if (PublishStatusObj[key] === value) {
      return PublishStatusObj[key] as unknown as PublishStatusEnum;
    }
  }
  return undefined; // Return undefined for invalid values
}
const ScheduleStatusObj = {
  Hidden: 0,
  VisibleComingSoon: 1,
  VisiblePreOrder: 2,
} as const
export type ScheduleStatusEnum = (typeof ScheduleStatusObj)[keyof typeof ScheduleStatusObj];
export function intToScheduleStatusEnum(value: number): ScheduleStatusEnum | undefined {
  for (const key in ScheduleStatusObj) {
    if (ScheduleStatusObj[key] === value) {
      return key as unknown as ScheduleStatusEnum;
    }
  }
  return undefined; // Return undefined for invalid values
}

const CategoryObj = {
  Publication : 0,
  Game : 1,
  Merchandise : 2,
  Bundle : 3
} as const
export type CategoryEnum = (typeof CategoryObj)[keyof typeof CategoryObj];
export function intToCategoryEnum(value: number): CategoryEnum | undefined {
  for (const key in CategoryObj) {
    if (CategoryObj[key] === value) {
      return key as unknown as CategoryEnum;
    }
  }
  return undefined; // Return undefined for invalid values
}

const SubCategoryObj = {
  //Publication
  Book: 7,
  Omnibus: 5,
  Annual : 2,
  OneShot:6,//One-Shot
  Volume:0,
  SingleIssue:1,//Single Issue
  //GraphicNovel : 4,//Obsolete
  BoxSet : 3, //Slipcase/Box Set
  Chapter : 8,

  //Games
  BoardGame: 1000,
  Puzzle: 1001,
  TradingCard : 1002,
  TarotCard : 1003,

  //Merchandise
  Litho : 2000,
  Print : 2001,
  Pin : 2002,
  Key : 2003,
  Apparel : 2004,
  FunPack : 2005,
  Other : 2006,

  //Bundles
  DigitalBundle : 3000
} as const
export type SubCategoryEnum = (typeof SubCategoryObj)[keyof typeof SubCategoryObj];
export function intToSubCategoryEnum(value: number): SubCategoryEnum | undefined {
  for (const key in SubCategoryObj) {
    if (SubCategoryObj[key] === value) {
      return key as unknown as SubCategoryEnum;
    }
  }
  return undefined; // Return undefined for invalid values
}
class FilterCriteria {
  type: number;
  genres: number[];
  sort: SortOrderEnum;
  ipTagId: number;
  imprintId: number;
  category?: CategoryEnum;
  subCategory: SubCategoryEnum;
  includeSeries: boolean;
  publishStatus?:PublishStatusEnum;
  scheduleStatus?:ScheduleStatusEnum;
  ageRatings?:[AgeRatingTier];
  hasDownloadablePriceTypeFlag?: number;
  hasShippablePriceTypeFlag?: number;
  isOnSale?:boolean;
  SeriesStatus?:"Ongoing"|"Complete";
  MinPrice?:number;
  MaxPrice?:number;
  newOnly:boolean;
}

class HomeListItem implements OrderedItem{
  id: number;
  product: NameTuple;
  series: NameTuple;
  order: number;
}


export {DashboardHomeListEntry,createDashboardHomeListEntryFrom, FilterCriteria, HomeListItem,SortOrderEnumObj,PublishStatusObj,ScheduleStatusObj, CategoryObj,SubCategoryObj};
