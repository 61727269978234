import axios, { AxiosError, AxiosResponse } from 'axios';
import { AccountInfo, SignupResponseStatus } from '../Models/AccountInfo';
import { Genre } from '../Models/Series';
import { SortOrder } from '../store/GeneralAtoms';
import CreateUserPayload from '../Models/CreateUserPayload';
import { Cart, CartDTO } from '../Models/Cart';
import apiClient, { ApiStatusModel, InkyApiError, InkyApiStatus } from './InkyAPI';
import { Purchase, PurchaseDTO, PurchaseRequest } from '../Models/Purchase';
import { Page } from 'src/store/ReaderStore';
import { FilterTuple } from '../Models/FilterTuple';
import Log from '../InkyPen/Services/Logger';

export enum ApiErrorStatus {
    // Returned whenever a network call reports an unauthorized request.
    // Reaction to this error should be to use the refresh-token to
    // get a new access-token, and retry whichever request that failed.
    InvalidAccessToken = "InvalidAccessToken"
}

export class ApiError extends Error {

    status: ApiErrorStatus;

    constructor(msg: string, status: ApiErrorStatus) {
        super(msg);

        this.status = status;

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ApiError.prototype);
    }

}

interface TokenResponse {
    access_token: string;
    refresh_token: string;
    expires_in: string;
    token_type: string;
}

interface ApiModel<T> {
    status: ApiStatusModel;
    response: T;
}

export class InkyApiV2{
    private static instance: InkyApiV2;
    private baseUrl = process.env.REACT_APP_BASE_URL;

    public static shared(): InkyApiV2{
        if(!this.instance){
            this.instance = new InkyApiV2();
        }
        return this.instance;
    }

    public RefreshToken: string;
    public AccessToken: string;
    public CartId: string;


    async LoginWithRefreshToken(): Promise<TokenResponse> {
        console.log('this.RefreshToken', this.RefreshToken);
        return await this.post<TokenResponse>('/account/token', { 'refresh_token' : this.RefreshToken });
    }

    async LoginWithRefreshTokenFromLocalStorage(refreshToken: string): Promise<TokenResponse> {
        console.log('this.RefreshToken', refreshToken);
        return await this.post<TokenResponse>('/account/token', { 'refresh_token' : refreshToken });
    }

    async FetchAccountInfo(): Promise<AccountInfo>{
        return await this.get<AccountInfo>('/account');
    }

    async Unsubscribe(): Promise<AccountInfo>{
        return await this.get<AccountInfo>('/account/unsubscribe');
    }

    async FetchGenres(): Promise<Genre[]> {
        return await this.get<Genre[]>('/genre');
    }

    async FetchSortOrders(): Promise<SortOrder[]> {
        return await this.get<SortOrder[]>('/discover/sortorder');
    }

    async FetchCreatorTypes(): Promise<FilterTuple[]> {
        return await this.get<FilterTuple[]>('dashboard/creators/types');
    }

    async CheckIfEmailExists(email): Promise<boolean> {
        // We encode the email with base64 (btoa) before we send it to the backend.
        // This saves us from encoding-issues.
        const encodedEmail = encodeURI(email);
        return await this.get<boolean>('account/exists/' + btoa(encodedEmail));
    }

    async Signup(userDetails: CreateUserPayload): Promise<SignupResponseStatus> {

        // Encode the credentials
        userDetails.email = btoa(userDetails.email);
        userDetails.password = btoa(userDetails.password);

        const response = await this.getPostHeader('/account/signupCustomerV2', userDetails);
        try {
            return SignupResponseStatus[SignupResponseStatus[response]];
        } catch (e) {
            return SignupResponseStatus.UnknownError;
        }
    }

    async FetchCart(): Promise<Cart>{
        console.log("Fetch Cart id", this.CartId);
        const response = await this.getV2<CartDTO>('/cart/' + this.CartId);
        // console.log("Response is", response);
        return Cart.createFromApi(response.response);
    }

    async FetchPurchaseByPaymentIntent(paymentIntent: string): Promise<Purchase>{
        console.log("Fetch Cart id", this.CartId);
        const response = await this.getV2<PurchaseDTO>('/order/purchaseFromPaymentIntent/' + paymentIntent);
        return Purchase.createFromApi(response.response);
    }

    async AddProductToCart(productId: number, variantId: number, amount = 1): Promise<Cart>{
        const apiRequest = { "productId": productId, "VariantId": variantId, "Amount" : Math.abs(amount)};
        console.debug(`Adding product with Product-ID ${productId} and Variant ${variantId} to cart.`);
        const response = await this.patch<CartDTO>('/cart/' + this.CartId, apiRequest);

        if(response.response instanceof CartDTO){
            console.log("Adding Product to Cart.");
        }
        else{
            const m = response.status.message as unknown;
            const message = m as string;
            if(message && message.toLowerCase().indexOf("japan")){
                console.log(message);

                return null;
            }
        }
        return Cart.createFromApi(response.response);
    }

    async DeductProductFromCart(productId: number, variantId: number, amount = 1): Promise<Cart>{
        const apiRequest = { "productId": productId, "VariantId": variantId, "Amount" : -Math.abs(amount)};
        console.debug(`Deducting product with Product-ID ${productId} and Variant ${variantId} from cart.`);
        const response = await this.patch<CartDTO>('/cart/' + this.CartId, apiRequest);
        return Cart.createFromApi(response.response);
    }

    async RemoveAllOfProductFromCart(productId: number, variantId: number): Promise<Cart>{
        const apiRequest = { "productId": productId, "VariantId": variantId, "Amount" : -1, "RemoveAll" : true};
        console.debug(`Removing all of product with Product-ID ${productId} and Variant ${variantId} from cart.`);
        const response = await this.patch<CartDTO>('/cart/' + this.CartId, apiRequest);
        return Cart.createFromApi(response.response);
    }

    async ClearCart(): Promise<Cart>{
        console.debug(`Clearing Cart.`);
        const response = await this.patch<CartDTO>('/cart/' + this.CartId + '/empty', null);
        return Cart.createFromApi(response.response);
    }

    //region Checkout-endpoints

    //TODO: Endpoint to fetch Purchase based of Purchaserequest
    async FetchPurchase(request: PurchaseRequest, widths: string = null): Promise<Purchase> {
        let params = "";
        if(widths) {
            params = `?widths=${widths}`;
        }
        const apiResponse: ApiModel<PurchaseDTO> = await apiClient.postResponse('/purchase-request'+ params, request, null);
        console.log("Purchase request Response", apiResponse);
        return Purchase.createFromApi(apiResponse.response);
    }

    //TODO: Endpoint to create Purchase based of Purchaserequest, and return paymentintent


    //TODO: Endpoint to inform about payment success

    //TODO: Endpoint to inform about payment failure

    //endregion

    //region Steam-endpoints
    async OpenSteamCheckoutForSubscription(steamId: string): Promise<void>{
        console.debug(`Initiate subscription trough steam checkout`);
        const response = await this.get<void>('/payment/steam/initiateTransaction/' + steamId, null);
    }
    //endregion

    //region Reader-endpoints
    async GetComicPageUrl(comicId: number, page: number): Promise<Page>{
        console.debug(`Getting comic page url.`);
        const response = await this.get<Page>('comic/' + comicId + '/pages/' + page, null);
        return response;
    }

    async UpdateReadingProgress(publicationId: number, page: number): Promise<string>{
        console.debug("Updating Reading-progress in Backend.")
        const response = await this.post<string>('mycomics/updateReadingProgressForPublication', {
            PublicationId: publicationId,
            ReadingProgress: page
        });

        return response;
    }

    //endregion

    //region Network-adapters
    private get<T>(url: string, params?: string[][]): Promise<T> {
        const fullUrl = new URL(url, this.baseUrl);

        if (params) {
            let param;
            for (param of params) {
                fullUrl.searchParams.append(param[0], param[1]);
            }
        }

        return axios.get(fullUrl.href, { headers: { Authorization: `Bearer ${this.AccessToken}`} })
            .then(function (response) {
                return response.data;
            })
            .catch((error: AxiosError) => {
                if(error.isAxiosError){
                    console.debug("Axios error response", error.response);

                    if(error.response.status == 401){
                        // Unauthorized error.
                        throw new ApiError("Unauthorized request.", ApiErrorStatus.InvalidAccessToken);
                    }

                console.log("Acioserror", error.response.status);
                }
                throw error;
            });

    }

    private getV2<T>(url: string, params?: string[][]): Promise<ApiModel<T>> {
        const fullUrl = new URL(url, this.baseUrl);

        if (params) {
            let param;
            for (param of params) {
                fullUrl.searchParams.append(param[0], param[1]);
            }
        }

        return axios.get(fullUrl.href, { headers: { Authorization: `Bearer ${this.AccessToken}`} })
            .then(function (response: AxiosResponse<ApiModel<T>>) {
                return response.data;
            })
            .catch((error: AxiosError) => {
                if(error.isAxiosError){
                    console.debug("Axios error response", error.response);

                    if(error.response.status == 401){
                        // Unauthorized error.
                        throw new ApiError("Unauthorized request.", ApiErrorStatus.InvalidAccessToken);
                    }

                    console.log("Acioserror", error.response.status);
                }
                throw error;
            });

    }

    private patch<T>(url: string, payload: object): Promise<ApiModel<T>> {
        const fullUrl = new URL(url, this.baseUrl);

        return axios.patch<ApiModel<T>>(fullUrl.href, payload, { headers: { Authorization: `Bearer ${this.AccessToken}`} })
            .then(function (response: AxiosResponse<ApiModel<T>>) {
                return response.data;
            })
            .catch((error: AxiosError) => {
                if(error.isAxiosError){
                    console.debug("Axios error response", error.response);

                    if(error.response.status == 401){
                        // Unauthorized error.
                        throw new ApiError("Unauthorized request.", ApiErrorStatus.InvalidAccessToken);
                    }

                    console.log("Axioserror", error.response.status);
                }
                throw error;
            });

    }

    private async post<T>(url: string, payload: object): Promise<T> {
        const fullUrl = new URL(url, this.baseUrl);
        const response = await axios.post<T>(fullUrl.href, payload, { headers: { Authorization: `Bearer ${this.AccessToken}`} });
        return response.data;
    }

    private async getPostHeader(url: string, payload: object): Promise<number> {
        const fullUrl = new URL(url, this.baseUrl);
        const response = await axios.post(fullUrl.href, payload, { headers: { Authorization: `Bearer ${this.AccessToken}`} });

        return response.status;
    }

    //endregion
    //region Klaviyo
    async GetListStatus(email: string, abortSignal?:AbortSignal): Promise<string[]> {
        const encodedEmail = encodeURIComponent(email);
        const result = await apiClient.getV4<ApiModel<string[]>>(`/klaviyo/GetListsStatus?email=` + encodedEmail, abortSignal);

        if(result.status.type ===  "Success") {
            return result.response;
        }
        Log.error("Failed to get list status from Klaviyo", result.status.message);
        return [];
    }

    async GetNewsletterListStatus (email: string, abortSignal?:AbortSignal): Promise<ListStatus> {
        const encodedEmail = encodeURIComponent(email);
        const result = await apiClient.getV4<ApiModel<ListStatus[]>>(`/klaviyo/GetNewslettersStatus?email=` + encodedEmail, abortSignal);

        if(result.status.type ===  "Success" && result.response.length > 0) {
            return result.response[0];
        }
        Log.error("Failed to get list status from Klaviyo", result.status.message);
        throw new InkyApiError("Failed to get newsletter list status from Klaviyo", InkyApiStatus.Default);
    }

    async SubscribeToNewsletters(email: string, attributes: string, abortSignal?:AbortSignal): Promise<void> {
        const encodedEmail = encodeURIComponent(email);
        const data = {
            attributes: attributes
        }

        const result = await apiClient.postV4<{attributes:string},ApiModel<any>>(`/klaviyo/SubscribeToNewsletters?email=` + encodedEmail,data, abortSignal);

        if(result.status.type !== "Success"){
            Log.error("Failed to subscribe to newsletters", result.status.message);
        }

    }

    async UnsubscribeFromNewsletters(email: string, abortSignal?:AbortSignal): Promise<void> {
        const encodedEmail = encodeURIComponent(email);

        const result = await apiClient.getV4<ApiModel<any>>(`/klaviyo/UnsubscribeFromNewsletters?email=` + encodedEmail, abortSignal);

        if(result.status.type !== "Success"){
            Log.error("Failed to unSubscribe to newsletters", result.status.message);
        }
    }

    //endregion
}

export type ListStatus =
    {
        "listName": "NewsLetter",
        "isOnList": boolean,
        "attributesString": string|undefined
    }