import { atom, useAtom, useAtomValue } from 'jotai';
import apiClient from '../API/InkyAPI';
import { DiscoverItem } from '../Models/DiscoverItem';
import { CancelTokenSource } from 'axios';
import { AccountInfoAtom } from './AccountStore';
import { AnalyticsIdAtom, AnalyticsUserProxyAtom } from './AnalyticsStore';
import InkyAPI from '../API/InkyAPI';
import AnalyticsEvent from '../Models/Analytics/AnalyticsEvent';
import { AccountInfo } from '../Models/AccountInfo';


const sendAnalyticsEvent = (accountInfo : AccountInfo, analyticsId: string, userProxy: string, query: string) => {
    InkyAPI.sendEvent(accountInfo,new AnalyticsEvent(analyticsId.toString(),
        "SEARCH",
        location.pathname,
        query,
        userProxy))
        .then(res => {});
}
export default sendAnalyticsEvent;

let cancelSearch: null | CancelTokenSource = null;
export const SearchResultLoadingStatus = atom<boolean>(false);
export const SearchResultAtom = atom<DiscoverItem[]>([]);
export const FetchSearchAtom = atom((get) => {
        return get(SearchResultAtom);
    },
    async (get, set, query: string) => {
        try {
            if (cancelSearch !== null) {
                cancelSearch.cancel();
            }

            const accountInfo = get(AccountInfoAtom);
            const analyticsId = get(AnalyticsIdAtom).toString();
            const userProxy = get(AnalyticsUserProxyAtom);

            console.log('searching for: ' + query);
            set(SearchResultLoadingStatus, true);
            const res = await apiClient.searchAndCancel(query, null);
            cancelSearch = res.controller;
            res.res.then((result) => {
                sendAnalyticsEvent(accountInfo,analyticsId,userProxy,query);
                console.log('res', result);
                set(SearchResultLoadingStatus, false);
                set(SearchResultAtom, result.response);
                return result;
            });

        } catch (e) {
            console.error('[SearchStore] Could not fetch Search-data: ' + e.message, e);
            set(SearchResultLoadingStatus, false);
            throw e;
        }
    },
);
export const SearchQueryAtom = atom<string>('');
export const FetchSearchQueryAtom = atom((get) => {
        return get(SearchQueryAtom);
    },
    async (get, set, query: string) => {
        let queryString = get(SearchQueryAtom);
        try {
            queryString = query;
            set(SearchQueryAtom, queryString);
        } catch (e) {
            console.error('[SearchStore] Could not fetch Search Query ' + e.message, e);
            throw e;
        }
    },
);


