import React from 'react';
import './KodanshaCart.scss';
import { Link } from '../../Devon/Components/InkyLink';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
    AddToCartAction,
    DeductFromCartAction,
    FetchCartAtom,
    RemoveAllOfProductFromCartAction,
    ShowCartAtom,
} from '../../store/CartStore';
import { Cart } from '../../Models/Cart';
import { CheckoutOverlayVisible, LoginOverlayVisible, LoginType, LoginTypeStore } from '../../store/OverlayStore';
import { ReactComponent as CancelIcon, ReactComponent as PlusIcon } from '../Assets/icons/PLUS.svg';
import { ReactComponent as ArrowIcon } from '../Assets/icons/buttonArrow.svg';
import { ReactComponent as MinusIcon } from '../Assets/icons/MINUS.svg';
import { CartItem, VariantType } from '../../Models/CartItem';
import { showScroll } from '../../utils/utils';
import InkyMageImg from '../../Devon/Components/InkyMageImg/InkyMageImg';
import { LoginStatusAtom } from '../../store/AccountStore';

interface ShowCartItemsProps {
    isScrollable?: boolean;
    fromSuccessCheckout?: boolean;
    CloseCarts?: (a: boolean) => void;
    shippingOverweight?: boolean;
}

export function ShowCartItems({
                                  CloseCarts,
                                  isScrollable = true,
                                  fromSuccessCheckout = false,
                                  shippingOverweight = false,
                              }: ShowCartItemsProps): JSX.Element {
    const cartAnyType = useAtomValue(FetchCartAtom);

    // Hack to fix types. Remove this when we are in TS strict-mode.
    const cart = cartAnyType as Cart;
    // No cart
    if (!cart) {
        return null;
    }

    // No items in cart
    if (cart.cartItems?.length === 0) {
        return (<h3 className='no-items-in-cart'>No items in cart</h3>);
    }

    


    return <React.Fragment>

        <ShowProductList cartItems={cart.cartItems} isScrollable={isScrollable}
                         CloseCarts={CloseCarts}
                         fromSuccessCheckout={fromSuccessCheckout} 
                         shippingOverweight={shippingOverweight}
        />

    </React.Fragment>;
}

// ProductList is a presentational component which can be used either in cart and success checkout page.
// Just pass the array of type CartItem, and it will render the data
// Instead ShowCartItems has access to the API to fetch the cart and pass to ShowProductList to present them.

interface ShowProductListProps {
    isScrollable?: boolean;
    fromSuccessCheckout?: boolean;
    CloseCarts?: (a: boolean) => void;
    cartItems?: CartItem[];
    shippingOverweight?: boolean;
}

const ShowProductList = ({
                             isScrollable = true,
                             fromSuccessCheckout = false,
                             cartItems,
                             shippingOverweight = false,
                         }: ShowProductListProps): JSX.Element => {

    const uniqueCartItems = [];
    cartItems?.forEach(item => {
        if (!uniqueCartItems.find(x => x.product.id == item.product.id && x.variant.id == item.variant.id)) {
            uniqueCartItems.push(item);
        }
    });
    uniqueCartItems.sort((a, b) => {
        if (a.product.name !== b.product.name) {
           return a.product.name.localeCompare(b.product.name);
        }
        if (a.distributionType === 'Print' && b.distributionType === 'Digital') {
            return -1;
        }
        if (a.distributionType === 'Digital' && b.distributionType === 'Print') {
            return 1;
        }
        return 0;
    });

    function findAmount(productID: number, variantId: number): number {
        return cartItems.filter(x => x.product.id == productID && x.variant.id == variantId).length;
    }

    return <div className={'cart-items-content'}
                style={{
                    overflowY: isScrollable ? 'auto' : 'hidden', maxHeight: isScrollable ? '40vh' : 'unset',
                    minHeight: isScrollable && '116px',
                }}>
        {uniqueCartItems.map((item, index) => (
            <CartItemRenderer
                key={index}
                fromSuccessCheckout={fromSuccessCheckout}
                shippingOverweight={shippingOverweight}
                index={index}
                item={item}
                amount={findAmount(item.product.id, item.variant.id)}
            />
        ))}
    </div>;
};

interface CartItemRendererProps {
    fromSuccessCheckout?: boolean;
    shippingOverweight?: boolean;
    index: number;
    item: CartItem;
    amount: number;
}

const CartItemRenderer = React.memo(function CartItemRenderer(
    {
        fromSuccessCheckout,
        shippingOverweight,
        index,
        item,
        amount,
    }: CartItemRendererProps
): React.ReactElement {
    const [showCart, setShowCart] = useAtom(ShowCartAtom);
    const [checkoutOverlayVisible, setCheckoutOverlayVisible] = useAtom(CheckoutOverlayVisible);

    const removeFromCartAction = useSetAtom(RemoveAllOfProductFromCartAction);
    const deductFromCartAction = useSetAtom(DeductFromCartAction);
    const addToCartAction = useSetAtom(AddToCartAction);

    const productId = item.product.id;
    const variantId = item.variant.id;
    const limit = item.variant.cartLimit ?? Infinity;

    const timeoutRef = React.useRef(0);
    const [isBusy, setIsBusy] = React.useState(false);
    const [delta, setDelta] = React.useState(0);

    const internalAmount = Math.min(Math.max(1, amount + delta), limit);
    const cannotDeduct = isBusy || internalAmount === 1;
    const cannotAdd = isBusy || internalAmount >= limit;

    const hideCart = React.useCallback((): void => {
        if (showCart) {
            setShowCart(false);
        }
        if (checkoutOverlayVisible) {
            setCheckoutOverlayVisible(false);
        }
    }, [showCart, setShowCart, checkoutOverlayVisible, setCheckoutOverlayVisible]);

    React.useEffect(() => {
        window.clearTimeout(timeoutRef.current);
        if (isBusy || delta === 0) {
            return;
        }
        timeoutRef.current = window.setTimeout(async (): Promise<void> => {
            setIsBusy(true);
            if (delta < 0) {
                const amountToDeduct = Math.min(Math.abs(delta), amount - 1);
                if (amountToDeduct !== 0) {
                    await deductFromCartAction({ productId, variantId, amount: amountToDeduct });
                }
            } else {
                const amountToAdd = Math.min(delta, limit - amount);
                if (amountToAdd !== 0) {
                    await addToCartAction({ productId, variantId, amount: amountToAdd });
                }
            }
            setDelta(0);
            setIsBusy(false);
        }, 500);
    }, [productId, variantId, limit, amount, isBusy, delta]);

    const addDeductJob = React.useCallback((): void => {
        setDelta(delta => delta - 1);
    }, []);

    const addAddJob = React.useCallback((): void => {
        setDelta(amount => amount + 1);
    }, []);

    const addRemoveJob = React.useCallback(async (): Promise<void> => {
        window.clearTimeout(timeoutRef.current);
        setIsBusy(true);
        setDelta(0);
        await removeFromCartAction({ productId, variantId });
        setIsBusy(false);
    }, [productId, variantId]);

    return (
        <div className={`cart-item ${index === 0 && 'cart-item-first'} ${fromSuccessCheckout && 'cart-item-success'}`}>
            <Link className='thumbnail' onClick={hideCart} to={`/product/${item.product.id}`}>
                {item.isOnSale && <span className='is-on-sale-tag'>ON SALE</span>}
                <InkyMageImg
                    src={item.product?.thumbnails[0]?.url}
                    alt={`cover for ${item.product.name}`}
                    className='cart-item-thumbnail'
                    s={{ width: 60 }}
                    m={{ width: 60 }}
                    l={{ width: 60 }}
                    xl={{ width: 60 }}
                />
            </Link>
            <Link className='metadata' onClick={hideCart} to={`/product/${item.product.id}`}>
                <span className='distribution-type'>{item.distributionType}</span>
                <h3>{item.product.name}</h3>
            </Link>
            <div className={`amount-cart ${item.distributionType === VariantType.Print && 'amount-cart-physical'}`}>
                {item.distributionType === VariantType.Print && (
                    <button
                        type='button'
                        className={`button-no-style ${cannotDeduct ? 'button-no-style-no-hover' : ''}`}
                        style={{ fill: cannotDeduct ? 'grey' : '' }}
                        onClick={(e): void => {
                            e.preventDefault();
                            addDeductJob();
                        }}
                        disabled={cannotDeduct}
                    >
                        <MinusIcon width={15} />
                    </button>
                )}
                <span className='quantity' style={shippingOverweight ? { color: 'red' } : {}}>
                    {internalAmount}
                </span>
                {item.distributionType === VariantType.Print && (
                    <button
                        type='button'
                        className={`button-no-style ${cannotAdd ? 'button-no-style-no-hover' : ''}`}
                        onClick={(e): void => {
                            e.preventDefault();
                            addAddJob();
                        }}
                        style={{ fill: cannotAdd ? 'grey' : '' }}
                        disabled={cannotAdd}
                    >
                        <PlusIcon width={15} style={shippingOverweight ? { fill: 'red' } : {}} />
                    </button>
                )}
            </div>
            <div className='price'>
                {item.price.multiply(internalAmount).toFormat()}
                {item.isOnSale && (<>{' '}<span>{item.fullPrice.multiply(internalAmount).toFormat()}</span></>)}
            </div>
            {!fromSuccessCheckout && (
                <div className={'actions'}>
                    <button
                        type='button'
                        aria-label='remove-from-cart'
                        className='button-no-style'
                        onClick={(e: React.MouseEvent): void => {
                            e.preventDefault();
                            addRemoveJob();
                        }}
                        disabled={cannotAdd}
                    >
                        <CancelIcon width={20} className='cart-item-cancel-icon' />
                    </button>
                </div>
            )}
        </div>
    );
});

interface ShowCartItemsTitleProps {
    isScrollable?: boolean;
    fromSuccessCheckout?: boolean;
}

export const ShowCartItemsTitle = ({ fromSuccessCheckout = false }: ShowCartItemsTitleProps): JSX.Element => {
    return <React.Fragment>

        <div
            className={`cart-headers ${fromSuccessCheckout && 'cart-headers-success'}`}>
            <span className='cart-header-one' style={{ color: fromSuccessCheckout && '#000000' }}>Product</span>
            <span className=''></span>
            <span className='cart-header-two' style={{ color: fromSuccessCheckout && '#000000' }}>Quantity</span>
            <span className='cart-header-three' style={{ color: fromSuccessCheckout && '#000000' }}>Price</span>
        </div>

    </React.Fragment>;
};

export function KodanshaCart({ CloseCarts }) {
    const [showCart, setShowCart] = useAtom(ShowCartAtom);
    const [isLoggedIn] = useAtom(LoginStatusAtom);
    const cartAnyType = useAtomValue(FetchCartAtom);
    const SetLoginOverlay = useSetAtom(LoginOverlayVisible);

    // Checkout actions
    const [, setCheckoutOverlay] = useAtom(CheckoutOverlayVisible);
    // Hack to fix types. Remove this when we are in TS strict-mode.
    const cart = cartAnyType as Cart;

    function showTotal(): JSX.Element {
        if (!cart) return;
        if (cart.cartItems?.length == 0) return null;

        return (<div className={'total-cart'}>
            <div className={'subtotal'}>
                <span className={'label-cart'}>Subtotal</span>
                <span className={'value'}>{Cart.getCartTotal(cart).toFormat()}</span>
            </div>
            <div className={'disclaimer cart-tax'}>Taxes Determined at checkout</div>
        </div>);
    }

    function showCheckoutCTA(): JSX.Element {
        if (!cart) return;
        if (cart.cartItems?.length == 0) return;
        return (<div className={'cart-checkout'} onClick={(e): void => {
            e.preventDefault();
            if (isLoggedIn === "LoggedIn") {
                setCheckoutOverlay(true);
                setShowCart(false)
            } else {
                SetLoginOverlay(true)
                setShowCart(false)
                LoginTypeStore.setLoginType(LoginType.Checkout);
            }

        }}><Link onClick={e => e.preventDefault()} to={'#'}>Checkout</Link>
            <ArrowIcon fill={'white'} />
        </div>);
    }

    return (<div className={'KodanshaCart'} onClick={(e) => {
        e.stopPropagation();
        showScroll();
        setShowCart(false);
    }}>
        <div className={'cart-section'} onClick={e => e.stopPropagation()}>


            {cart.cartItems?.length !== 0 && <span className='cart-order-title'>Cart Order</span>}
            {cart.cartItems?.length !== 0 && <ShowCartItemsTitle />}


            <ShowCartItems CloseCarts={CloseCarts} />


            {showTotal()}
            {showCheckoutCTA()}
            <div className={'cart-cta'} onClick={(e) => {
                e.preventDefault();
                showScroll();
                setShowCart(!showCart);
            }}>
                <button aria-label={'continue-shopping'}>Continue Shopping
                </button>
            </div>
        </div>
    </div>);
}